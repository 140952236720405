import React, {useState} from "react"
import { buildSeo } from "../../utils/seo";
import LinkFade from "../../components/linkfade";
import Button from "../../components/button";
import {MarqueeTitle} from "../../components/cases/marquee-title";
import {Image} from "../../components/image";


/**
 * Render Category page
 * On first page show first article as Heading
 * List next articles in a list
 *
 * @param pageContext CategoryPageContext
 * @return {*}
 * @constructor
 */
const ListPage = ({ pageContext, transitionStatus, entry, exit }) => {
  let { articles} = pageContext; 


  return (
    <>
      {buildSeo({
        title: 'Mes prestations de freelance en développement front-end & en design',
        description: "J'ai pu travailler sur un bon nombre de projets, dans divers langages et dans divers cadres. Découvre les !"
      })}
      <section className={"container mb-16"}>
        <div>
          <MarqueeTitle text={"Prestations. Prestations. Prestations. Prestations."}/>
          <div className={"page-introduction"}>
            <h1 className={"h2"}>Un site performant, beau, et à votre image</h1>
              <p>Pourquoi vouloir un site lambda, quand on peut avoir un site qui émerveillera vos clients 🌻 ?</p>
              <p>Je vous propose de travailler main dans la main pour concevoir et imaginer un site à votre image, qui saura vous ravir. Vous serez fiers de votre site !</p>
          </div>
        </div>
      </section>
        <section className={"blue-section section mb-8"}>
          <div className={"py-16"}>
            {articles.map((value, index) => {
                if(value.frontmatter.show_homepage)
                 return <ImportantListPageContent   key={index} value={value} index={index} />
                else
                    return (<></>);
            })}
          </div>
        </section>
    </>
  )
}

const ImportantListPageContent = (({value, index, }) => {
    const [toggle, setToggle] = useState(false);
    return (
        <div onClick={() => {setToggle(!toggle)}} className={"prestation-list-item " + (toggle ? "open" : "")}>
            <Image className="prestation-image" src={value.frontmatter.thumbnail} alt={value.frontmatter.title}/>
            <div className={"container"}>
              <div className={"prestation-content"}>
                  <div className={"prestation-header"}>
                      <h3 className={"h3"}>
                          {(value.frontmatter.enable_page) && (<LinkFade   key={index} url={"../" +value.fields.path}  >{value.frontmatter.title}</LinkFade>)}
                          {!(value.frontmatter.enable_page) && (value.frontmatter.title)}
                      </h3>
                      <span className={"prestation-header-open"}></span>
                  </div>
                  <div className={"prestation-body"}>
                      {value.frontmatter.description.map(v => (<p>{v}</p>))}
                      {value.frontmatter.can_do && (
                          <ul>
                              {value.frontmatter.can_do.map(v => (<li>{v}</li>))}
                          </ul>
                      )}
                      <Button link={"/contact/"} name={"Contactez-moi"}/>
                  </div>
              </div>
            </div>
        </div>
    )
});



export default ListPage
